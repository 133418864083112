<template>
  <div class="topCreatorCard" v-if="creator">
    <picture class="creator-picture" :style="`--picture:url(${encode_url(image)});`"></picture>
    <p class="creator-name">{{ creator.name }}</p>
    <p class="creator-title creator-label-title">
      <span class="exclusive" v-if="creator.exclusive">{{ $locale["landing"]["model-exclusive-label"] }}</span>
      <span v-else>{{ $locale["landing"]["model-label"] }}</span>
      <iconic v-if="creator.exclusive" name="mf_check" />
    </p>
  </div>
</template>

<script>
export default {
  props: ["creator"],
  data: function() {
    return {
      image: `https://myfans1a.s3.amazonaws.com/creators/landing/thumbnails/${this.creator.user}_creator.jpg`,
    };
  },
  methods: {
    getLandingData: function() {
      if (!this.creator.landing) return this.image;

      if (!this.creator.landing.top) return this.image;

      this.image = this.creator.landing.top.src;

      return this.creator.landing.top.src;
    },
    getMedia: function() {
      const img = new Image();
      img.src = this.getLandingData();

      img.onload = () => {};

      img.onerror = (err) => {
        this.getFisrtFromFiles();
      };
    },
    getFisrtFromFiles: function() {
      const _media = this.creator.files.find(function(m) {
        return m.type.includes("image");
      });

      if (_media) {
        this.image = _media.src;
      }
    },
  },
  mounted: function() {
    this.getMedia();
  },
};
</script>
